import { z } from 'zod'
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export type Company = {
  __typename?: 'Company';
  domains: Array<Maybe<Scalars['String']['output']>>;
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  requestPrice: Scalars['Int']['output'];
};

export type CreateCheckoutSessionResponse = {
  __typename?: 'CreateCheckoutSessionResponse';
  sessionId: Scalars['String']['output'];
};

export type CreateRequestInput = {
  companyId: Scalars['String']['input'];
  jobTitle: Scalars['String']['input'];
  jobUrl: Scalars['String']['input'];
  resumeKey: Scalars['String']['input'];
  type: RequestType;
};

export enum ErrorType {
  InternalServerError = 'INTERNAL_SERVER_ERROR',
  InvalidInput = 'INVALID_INPUT',
  NotFound = 'NOT_FOUND',
  Unauthenticated = 'UNAUTHENTICATED',
  Unauthorized = 'UNAUTHORIZED'
}

export type Mentor = {
  __typename?: 'Mentor';
  company: Company;
  email: Scalars['String']['output'];
  isDeleted: Scalars['Boolean']['output'];
  pendingEarnings: Scalars['Int']['output'];
  verificationStatus: MentorStatus;
};

export type MentorOnboardInput = {
  companyId: Scalars['String']['input'];
  email: Scalars['String']['input'];
};

export enum MentorStatus {
  NotStarted = 'NOT_STARTED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  Verified = 'VERIFIED'
}

export type MentorVerificationInput = {
  token: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  adminApproveRequest: Scalars['Boolean']['output'];
  adminDenyRequest: Scalars['Boolean']['output'];
  claimRequest: Scalars['Boolean']['output'];
  completeRequest: Scalars['Boolean']['output'];
  createCheckoutSession: CreateCheckoutSessionResponse;
  createRequest?: Maybe<Request>;
  deleteRequest: Scalars['Boolean']['output'];
  dropRequest: Scalars['Boolean']['output'];
  onboardMentor?: Maybe<MentorStatus>;
  resumeReview: Scalars['Boolean']['output'];
  updateUser: Scalars['Boolean']['output'];
  verifyMentor?: Maybe<MentorStatus>;
};


export type MutationAdminApproveRequestArgs = {
  requestId: Scalars['ID']['input'];
};


export type MutationAdminDenyRequestArgs = {
  requestId: Scalars['ID']['input'];
};


export type MutationClaimRequestArgs = {
  requestId: Scalars['ID']['input'];
};


export type MutationCompleteRequestArgs = {
  imageKeys: Array<Scalars['String']['input']>;
  requestId: Scalars['ID']['input'];
};


export type MutationCreateRequestArgs = {
  input: CreateRequestInput;
};


export type MutationDeleteRequestArgs = {
  requestId: Scalars['ID']['input'];
};


export type MutationDropRequestArgs = {
  requestId: Scalars['ID']['input'];
};


export type MutationOnboardMentorArgs = {
  input: MentorOnboardInput;
};


export type MutationResumeReviewArgs = {
  input: ResumeReviewInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationVerifyMentorArgs = {
  input: MentorVerificationInput;
};

export type Name = {
  __typename?: 'Name';
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
};

export enum PaymentStatus {
  Charged = 'CHARGED',
  HoldPlaced = 'HOLD_PLACED',
  NotStarted = 'NOT_STARTED',
  Refunded = 'REFUNDED'
}

export enum PayoutStatus {
  Completed = 'COMPLETED',
  Denied = 'DENIED',
  NotStarted = 'NOT_STARTED',
  Pending = 'PENDING'
}

export type Query = {
  __typename?: 'Query';
  companies?: Maybe<Array<Company>>;
  me: User;
  myOffers?: Maybe<Array<Request>>;
  myReferrals?: Maybe<Array<Request>>;
  myRequests?: Maybe<Array<Request>>;
  request?: Maybe<Request>;
  requestPrice: Scalars['Int']['output'];
  requestsInReview?: Maybe<Array<Request>>;
};


export type QueryRequestArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRequestPriceArgs = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
};

export type Request = {
  __typename?: 'Request';
  company: Company;
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  jobTitle: Scalars['String']['output'];
  jobUrl: Scalars['String']['output'];
  requestPrice: Scalars['Int']['output'];
  signedResumeUrl?: Maybe<Scalars['String']['output']>;
  status: RequestStatus;
  type: RequestType;
  updatedAt: Scalars['String']['output'];
};

export enum RequestStatus {
  Cancelled = 'CANCELLED',
  Expired = 'EXPIRED',
  Fulfilled = 'FULFILLED',
  InProgress = 'IN_PROGRESS',
  InReview = 'IN_REVIEW',
  Open = 'OPEN'
}

export enum RequestType {
  Referral = 'REFERRAL'
}

export type ResumeReviewInput = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  resumeKey: Scalars['String']['input'];
};

export type StripeCard = {
  __typename?: 'StripeCard';
  brand: Scalars['String']['output'];
  expMonth: Scalars['Int']['output'];
  expYear: Scalars['Int']['output'];
  last4: Scalars['String']['output'];
};

export type UpdateUserInput = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isAdmin: Scalars['Boolean']['output'];
  mentor?: Maybe<Mentor>;
  name: Name;
  stripeCustomerId: Scalars['String']['output'];
  stripePaymentMethod?: Maybe<StripeCard>;
};

export type RequestsInReviewQueryVariables = Exact<{ [key: string]: never; }>;


export type RequestsInReviewQuery = { __typename?: 'Query', requestsInReview?: Array<{ __typename?: 'Request', id: string, status: RequestStatus, createdAt: string, requestPrice: number, type: RequestType, jobTitle: string, jobUrl: string, signedResumeUrl?: string | null, company: { __typename?: 'Company', name: string } }> | null };

export type AdminApproveRequestMutationVariables = Exact<{
  requestId: Scalars['ID']['input'];
}>;


export type AdminApproveRequestMutation = { __typename?: 'Mutation', adminApproveRequest: boolean };

export type AdminDenyRequestMutationVariables = Exact<{
  requestId: Scalars['ID']['input'];
}>;


export type AdminDenyRequestMutation = { __typename?: 'Mutation', adminDenyRequest: boolean };

export type HomeCompanyFragment = { __typename?: 'Company', id: string, name: string };

export type HomeCompaniesQueryVariables = Exact<{ [key: string]: never; }>;


export type HomeCompaniesQuery = { __typename?: 'Query', companies?: Array<{ __typename?: 'Company', id: string, name: string }> | null };

export type CompanyFragment = { __typename?: 'Company', id: string, name: string, domains: Array<string | null>, imageUrl?: string | null };

export type CompaniesQueryVariables = Exact<{ [key: string]: never; }>;


export type CompaniesQuery = { __typename?: 'Query', companies?: Array<{ __typename?: 'Company', id: string, name: string, domains: Array<string | null>, imageUrl?: string | null }> | null };

export type OnboardMentorMutationVariables = Exact<{
  input: MentorOnboardInput;
}>;


export type OnboardMentorMutation = { __typename?: 'Mutation', onboardMentor?: MentorStatus | null };

export type VerifyMentorMutationVariables = Exact<{
  input: MentorVerificationInput;
}>;


export type VerifyMentorMutation = { __typename?: 'Mutation', verifyMentor?: MentorStatus | null };

export type MyOffersQueryVariables = Exact<{ [key: string]: never; }>;


export type MyOffersQuery = { __typename?: 'Query', myOffers?: Array<{ __typename?: 'Request', id: string, status: RequestStatus, createdAt: string, requestPrice: number, type: RequestType, jobTitle: string, jobUrl: string, signedResumeUrl?: string | null, company: { __typename?: 'Company', name: string } }> | null };

export type MyReferralsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyReferralsQuery = { __typename?: 'Query', myReferrals?: Array<{ __typename?: 'Request', id: string, status: RequestStatus, createdAt: string, requestPrice: number, type: RequestType, jobTitle: string, jobUrl: string, signedResumeUrl?: string | null, company: { __typename?: 'Company', name: string } }> | null };

export type RequestFragment = { __typename?: 'Request', id: string, status: RequestStatus, createdAt: string, requestPrice: number, type: RequestType, jobTitle: string, jobUrl: string, signedResumeUrl?: string | null, company: { __typename?: 'Company', name: string } };

export type MyRequestsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyRequestsQuery = { __typename?: 'Query', myRequests?: Array<{ __typename?: 'Request', id: string, status: RequestStatus, createdAt: string, requestPrice: number, type: RequestType, jobTitle: string, jobUrl: string, signedResumeUrl?: string | null, company: { __typename?: 'Company', name: string } }> | null };

export type RequestQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type RequestQuery = { __typename?: 'Query', request?: { __typename?: 'Request', id: string, status: RequestStatus, createdAt: string, requestPrice: number, type: RequestType, jobTitle: string, jobUrl: string, signedResumeUrl?: string | null, company: { __typename?: 'Company', name: string } } | null };

export type CreateRequestDataQueryVariables = Exact<{ [key: string]: never; }>;


export type CreateRequestDataQuery = { __typename?: 'Query', requestPrice: number, companies?: Array<{ __typename?: 'Company', id: string, name: string, domains: Array<string | null>, imageUrl?: string | null }> | null };

export type CreateCheckoutSessionMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateCheckoutSessionMutation = { __typename?: 'Mutation', createCheckoutSession: { __typename?: 'CreateCheckoutSessionResponse', sessionId: string } };

export type CreateRequestMutationVariables = Exact<{
  input: CreateRequestInput;
}>;


export type CreateRequestMutation = { __typename?: 'Mutation', createRequest?: { __typename?: 'Request', id: string, status: RequestStatus, createdAt: string, requestPrice: number, type: RequestType, jobTitle: string, jobUrl: string, signedResumeUrl?: string | null, company: { __typename?: 'Company', name: string } } | null };

export type ClaimRequestMutationVariables = Exact<{
  requestId: Scalars['ID']['input'];
}>;


export type ClaimRequestMutation = { __typename?: 'Mutation', claimRequest: boolean };

export type DropRequestMutationVariables = Exact<{
  requestId: Scalars['ID']['input'];
}>;


export type DropRequestMutation = { __typename?: 'Mutation', dropRequest: boolean };

export type CompleteRequestMutationVariables = Exact<{
  requestId: Scalars['ID']['input'];
  imageKeys: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type CompleteRequestMutation = { __typename?: 'Mutation', completeRequest: boolean };

export type DeleteRequestMutationVariables = Exact<{
  requestId: Scalars['ID']['input'];
}>;


export type DeleteRequestMutation = { __typename?: 'Mutation', deleteRequest: boolean };

export type ResumeReviewMutationVariables = Exact<{
  input: ResumeReviewInput;
}>;


export type ResumeReviewMutation = { __typename?: 'Mutation', resumeReview: boolean };

export type UserFragment = { __typename?: 'User', id: string, email: string, isAdmin: boolean, stripeCustomerId: string, stripePaymentMethod?: { __typename?: 'StripeCard', brand: string, last4: string, expMonth: number, expYear: number } | null, name: { __typename?: 'Name', firstName: string, lastName: string }, mentor?: { __typename?: 'Mentor', email: string, verificationStatus: MentorStatus, isDeleted: boolean, pendingEarnings: number, company: { __typename?: 'Company', id: string, name: string, domains: Array<string | null>, imageUrl?: string | null } } | null };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me: { __typename?: 'User', id: string, email: string, isAdmin: boolean, stripeCustomerId: string, stripePaymentMethod?: { __typename?: 'StripeCard', brand: string, last4: string, expMonth: number, expYear: number } | null, name: { __typename?: 'Name', firstName: string, lastName: string }, mentor?: { __typename?: 'Mentor', email: string, verificationStatus: MentorStatus, isDeleted: boolean, pendingEarnings: number, company: { __typename?: 'Company', id: string, name: string, domains: Array<string | null>, imageUrl?: string | null } } | null } };

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: boolean };

export const HomeCompanyFragmentDoc = gql`
    fragment HomeCompany on Company {
  id
  name
}
    `;
export const CompanyFragmentDoc = gql`
    fragment Company on Company {
  id
  name
  domains
  imageUrl
}
    `;
export const RequestFragmentDoc = gql`
    fragment Request on Request {
  id
  status
  createdAt
  company {
    name
  }
  requestPrice
  type
  jobTitle
  jobUrl
  signedResumeUrl
}
    `;
export const UserFragmentDoc = gql`
    fragment User on User {
  id
  email
  isAdmin
  stripeCustomerId
  stripePaymentMethod {
    brand
    last4
    expMonth
    expYear
  }
  name {
    firstName
    lastName
  }
  mentor {
    email
    verificationStatus
    isDeleted
    pendingEarnings
    company {
      id
      name
      domains
      imageUrl
    }
  }
}
    `;
export const RequestsInReviewDocument = gql`
    query RequestsInReview {
  requestsInReview {
    ...Request
  }
}
    ${RequestFragmentDoc}`;

/**
 * __useRequestsInReviewQuery__
 *
 * To run a query within a React component, call `useRequestsInReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestsInReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestsInReviewQuery({
 *   variables: {
 *   },
 * });
 */
export function useRequestsInReviewQuery(baseOptions?: Apollo.QueryHookOptions<RequestsInReviewQuery, RequestsInReviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RequestsInReviewQuery, RequestsInReviewQueryVariables>(RequestsInReviewDocument, options);
      }
export function useRequestsInReviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RequestsInReviewQuery, RequestsInReviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RequestsInReviewQuery, RequestsInReviewQueryVariables>(RequestsInReviewDocument, options);
        }
export function useRequestsInReviewSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RequestsInReviewQuery, RequestsInReviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RequestsInReviewQuery, RequestsInReviewQueryVariables>(RequestsInReviewDocument, options);
        }
export type RequestsInReviewQueryHookResult = ReturnType<typeof useRequestsInReviewQuery>;
export type RequestsInReviewLazyQueryHookResult = ReturnType<typeof useRequestsInReviewLazyQuery>;
export type RequestsInReviewSuspenseQueryHookResult = ReturnType<typeof useRequestsInReviewSuspenseQuery>;
export type RequestsInReviewQueryResult = Apollo.QueryResult<RequestsInReviewQuery, RequestsInReviewQueryVariables>;
export const AdminApproveRequestDocument = gql`
    mutation AdminApproveRequest($requestId: ID!) {
  adminApproveRequest(requestId: $requestId)
}
    `;
export type AdminApproveRequestMutationFn = Apollo.MutationFunction<AdminApproveRequestMutation, AdminApproveRequestMutationVariables>;

/**
 * __useAdminApproveRequestMutation__
 *
 * To run a mutation, you first call `useAdminApproveRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminApproveRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminApproveRequestMutation, { data, loading, error }] = useAdminApproveRequestMutation({
 *   variables: {
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useAdminApproveRequestMutation(baseOptions?: Apollo.MutationHookOptions<AdminApproveRequestMutation, AdminApproveRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminApproveRequestMutation, AdminApproveRequestMutationVariables>(AdminApproveRequestDocument, options);
      }
export type AdminApproveRequestMutationHookResult = ReturnType<typeof useAdminApproveRequestMutation>;
export type AdminApproveRequestMutationResult = Apollo.MutationResult<AdminApproveRequestMutation>;
export type AdminApproveRequestMutationOptions = Apollo.BaseMutationOptions<AdminApproveRequestMutation, AdminApproveRequestMutationVariables>;
export const AdminDenyRequestDocument = gql`
    mutation AdminDenyRequest($requestId: ID!) {
  adminDenyRequest(requestId: $requestId)
}
    `;
export type AdminDenyRequestMutationFn = Apollo.MutationFunction<AdminDenyRequestMutation, AdminDenyRequestMutationVariables>;

/**
 * __useAdminDenyRequestMutation__
 *
 * To run a mutation, you first call `useAdminDenyRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDenyRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDenyRequestMutation, { data, loading, error }] = useAdminDenyRequestMutation({
 *   variables: {
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useAdminDenyRequestMutation(baseOptions?: Apollo.MutationHookOptions<AdminDenyRequestMutation, AdminDenyRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminDenyRequestMutation, AdminDenyRequestMutationVariables>(AdminDenyRequestDocument, options);
      }
export type AdminDenyRequestMutationHookResult = ReturnType<typeof useAdminDenyRequestMutation>;
export type AdminDenyRequestMutationResult = Apollo.MutationResult<AdminDenyRequestMutation>;
export type AdminDenyRequestMutationOptions = Apollo.BaseMutationOptions<AdminDenyRequestMutation, AdminDenyRequestMutationVariables>;
export const HomeCompaniesDocument = gql`
    query HomeCompanies {
  companies {
    ...HomeCompany
  }
}
    ${HomeCompanyFragmentDoc}`;

/**
 * __useHomeCompaniesQuery__
 *
 * To run a query within a React component, call `useHomeCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useHomeCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<HomeCompaniesQuery, HomeCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomeCompaniesQuery, HomeCompaniesQueryVariables>(HomeCompaniesDocument, options);
      }
export function useHomeCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomeCompaniesQuery, HomeCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomeCompaniesQuery, HomeCompaniesQueryVariables>(HomeCompaniesDocument, options);
        }
export function useHomeCompaniesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<HomeCompaniesQuery, HomeCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HomeCompaniesQuery, HomeCompaniesQueryVariables>(HomeCompaniesDocument, options);
        }
export type HomeCompaniesQueryHookResult = ReturnType<typeof useHomeCompaniesQuery>;
export type HomeCompaniesLazyQueryHookResult = ReturnType<typeof useHomeCompaniesLazyQuery>;
export type HomeCompaniesSuspenseQueryHookResult = ReturnType<typeof useHomeCompaniesSuspenseQuery>;
export type HomeCompaniesQueryResult = Apollo.QueryResult<HomeCompaniesQuery, HomeCompaniesQueryVariables>;
export const CompaniesDocument = gql`
    query Companies {
  companies {
    ...Company
  }
}
    ${CompanyFragmentDoc}`;

/**
 * __useCompaniesQuery__
 *
 * To run a query within a React component, call `useCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<CompaniesQuery, CompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompaniesQuery, CompaniesQueryVariables>(CompaniesDocument, options);
      }
export function useCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompaniesQuery, CompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompaniesQuery, CompaniesQueryVariables>(CompaniesDocument, options);
        }
export function useCompaniesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CompaniesQuery, CompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CompaniesQuery, CompaniesQueryVariables>(CompaniesDocument, options);
        }
export type CompaniesQueryHookResult = ReturnType<typeof useCompaniesQuery>;
export type CompaniesLazyQueryHookResult = ReturnType<typeof useCompaniesLazyQuery>;
export type CompaniesSuspenseQueryHookResult = ReturnType<typeof useCompaniesSuspenseQuery>;
export type CompaniesQueryResult = Apollo.QueryResult<CompaniesQuery, CompaniesQueryVariables>;
export const OnboardMentorDocument = gql`
    mutation OnboardMentor($input: MentorOnboardInput!) {
  onboardMentor(input: $input)
}
    `;
export type OnboardMentorMutationFn = Apollo.MutationFunction<OnboardMentorMutation, OnboardMentorMutationVariables>;

/**
 * __useOnboardMentorMutation__
 *
 * To run a mutation, you first call `useOnboardMentorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnboardMentorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onboardMentorMutation, { data, loading, error }] = useOnboardMentorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOnboardMentorMutation(baseOptions?: Apollo.MutationHookOptions<OnboardMentorMutation, OnboardMentorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OnboardMentorMutation, OnboardMentorMutationVariables>(OnboardMentorDocument, options);
      }
export type OnboardMentorMutationHookResult = ReturnType<typeof useOnboardMentorMutation>;
export type OnboardMentorMutationResult = Apollo.MutationResult<OnboardMentorMutation>;
export type OnboardMentorMutationOptions = Apollo.BaseMutationOptions<OnboardMentorMutation, OnboardMentorMutationVariables>;
export const VerifyMentorDocument = gql`
    mutation VerifyMentor($input: MentorVerificationInput!) {
  verifyMentor(input: $input)
}
    `;
export type VerifyMentorMutationFn = Apollo.MutationFunction<VerifyMentorMutation, VerifyMentorMutationVariables>;

/**
 * __useVerifyMentorMutation__
 *
 * To run a mutation, you first call `useVerifyMentorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyMentorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyMentorMutation, { data, loading, error }] = useVerifyMentorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyMentorMutation(baseOptions?: Apollo.MutationHookOptions<VerifyMentorMutation, VerifyMentorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyMentorMutation, VerifyMentorMutationVariables>(VerifyMentorDocument, options);
      }
export type VerifyMentorMutationHookResult = ReturnType<typeof useVerifyMentorMutation>;
export type VerifyMentorMutationResult = Apollo.MutationResult<VerifyMentorMutation>;
export type VerifyMentorMutationOptions = Apollo.BaseMutationOptions<VerifyMentorMutation, VerifyMentorMutationVariables>;
export const MyOffersDocument = gql`
    query MyOffers {
  myOffers {
    ...Request
  }
}
    ${RequestFragmentDoc}`;

/**
 * __useMyOffersQuery__
 *
 * To run a query within a React component, call `useMyOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyOffersQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyOffersQuery(baseOptions?: Apollo.QueryHookOptions<MyOffersQuery, MyOffersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyOffersQuery, MyOffersQueryVariables>(MyOffersDocument, options);
      }
export function useMyOffersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyOffersQuery, MyOffersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyOffersQuery, MyOffersQueryVariables>(MyOffersDocument, options);
        }
export function useMyOffersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MyOffersQuery, MyOffersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MyOffersQuery, MyOffersQueryVariables>(MyOffersDocument, options);
        }
export type MyOffersQueryHookResult = ReturnType<typeof useMyOffersQuery>;
export type MyOffersLazyQueryHookResult = ReturnType<typeof useMyOffersLazyQuery>;
export type MyOffersSuspenseQueryHookResult = ReturnType<typeof useMyOffersSuspenseQuery>;
export type MyOffersQueryResult = Apollo.QueryResult<MyOffersQuery, MyOffersQueryVariables>;
export const MyReferralsDocument = gql`
    query MyReferrals {
  myReferrals {
    ...Request
  }
}
    ${RequestFragmentDoc}`;

/**
 * __useMyReferralsQuery__
 *
 * To run a query within a React component, call `useMyReferralsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyReferralsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyReferralsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyReferralsQuery(baseOptions?: Apollo.QueryHookOptions<MyReferralsQuery, MyReferralsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyReferralsQuery, MyReferralsQueryVariables>(MyReferralsDocument, options);
      }
export function useMyReferralsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyReferralsQuery, MyReferralsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyReferralsQuery, MyReferralsQueryVariables>(MyReferralsDocument, options);
        }
export function useMyReferralsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MyReferralsQuery, MyReferralsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MyReferralsQuery, MyReferralsQueryVariables>(MyReferralsDocument, options);
        }
export type MyReferralsQueryHookResult = ReturnType<typeof useMyReferralsQuery>;
export type MyReferralsLazyQueryHookResult = ReturnType<typeof useMyReferralsLazyQuery>;
export type MyReferralsSuspenseQueryHookResult = ReturnType<typeof useMyReferralsSuspenseQuery>;
export type MyReferralsQueryResult = Apollo.QueryResult<MyReferralsQuery, MyReferralsQueryVariables>;
export const MyRequestsDocument = gql`
    query MyRequests {
  myRequests {
    ...Request
  }
}
    ${RequestFragmentDoc}`;

/**
 * __useMyRequestsQuery__
 *
 * To run a query within a React component, call `useMyRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyRequestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyRequestsQuery(baseOptions?: Apollo.QueryHookOptions<MyRequestsQuery, MyRequestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyRequestsQuery, MyRequestsQueryVariables>(MyRequestsDocument, options);
      }
export function useMyRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyRequestsQuery, MyRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyRequestsQuery, MyRequestsQueryVariables>(MyRequestsDocument, options);
        }
export function useMyRequestsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MyRequestsQuery, MyRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MyRequestsQuery, MyRequestsQueryVariables>(MyRequestsDocument, options);
        }
export type MyRequestsQueryHookResult = ReturnType<typeof useMyRequestsQuery>;
export type MyRequestsLazyQueryHookResult = ReturnType<typeof useMyRequestsLazyQuery>;
export type MyRequestsSuspenseQueryHookResult = ReturnType<typeof useMyRequestsSuspenseQuery>;
export type MyRequestsQueryResult = Apollo.QueryResult<MyRequestsQuery, MyRequestsQueryVariables>;
export const RequestDocument = gql`
    query Request($id: ID!) {
  request(id: $id) {
    ...Request
  }
}
    ${RequestFragmentDoc}`;

/**
 * __useRequestQuery__
 *
 * To run a query within a React component, call `useRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRequestQuery(baseOptions: Apollo.QueryHookOptions<RequestQuery, RequestQueryVariables> & ({ variables: RequestQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RequestQuery, RequestQueryVariables>(RequestDocument, options);
      }
export function useRequestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RequestQuery, RequestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RequestQuery, RequestQueryVariables>(RequestDocument, options);
        }
export function useRequestSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RequestQuery, RequestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RequestQuery, RequestQueryVariables>(RequestDocument, options);
        }
export type RequestQueryHookResult = ReturnType<typeof useRequestQuery>;
export type RequestLazyQueryHookResult = ReturnType<typeof useRequestLazyQuery>;
export type RequestSuspenseQueryHookResult = ReturnType<typeof useRequestSuspenseQuery>;
export type RequestQueryResult = Apollo.QueryResult<RequestQuery, RequestQueryVariables>;
export const CreateRequestDataDocument = gql`
    query CreateRequestData {
  companies {
    ...Company
  }
  requestPrice
}
    ${CompanyFragmentDoc}`;

/**
 * __useCreateRequestDataQuery__
 *
 * To run a query within a React component, call `useCreateRequestDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateRequestDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateRequestDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useCreateRequestDataQuery(baseOptions?: Apollo.QueryHookOptions<CreateRequestDataQuery, CreateRequestDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CreateRequestDataQuery, CreateRequestDataQueryVariables>(CreateRequestDataDocument, options);
      }
export function useCreateRequestDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreateRequestDataQuery, CreateRequestDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CreateRequestDataQuery, CreateRequestDataQueryVariables>(CreateRequestDataDocument, options);
        }
export function useCreateRequestDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CreateRequestDataQuery, CreateRequestDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CreateRequestDataQuery, CreateRequestDataQueryVariables>(CreateRequestDataDocument, options);
        }
export type CreateRequestDataQueryHookResult = ReturnType<typeof useCreateRequestDataQuery>;
export type CreateRequestDataLazyQueryHookResult = ReturnType<typeof useCreateRequestDataLazyQuery>;
export type CreateRequestDataSuspenseQueryHookResult = ReturnType<typeof useCreateRequestDataSuspenseQuery>;
export type CreateRequestDataQueryResult = Apollo.QueryResult<CreateRequestDataQuery, CreateRequestDataQueryVariables>;
export const CreateCheckoutSessionDocument = gql`
    mutation CreateCheckoutSession {
  createCheckoutSession {
    sessionId
  }
}
    `;
export type CreateCheckoutSessionMutationFn = Apollo.MutationFunction<CreateCheckoutSessionMutation, CreateCheckoutSessionMutationVariables>;

/**
 * __useCreateCheckoutSessionMutation__
 *
 * To run a mutation, you first call `useCreateCheckoutSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCheckoutSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCheckoutSessionMutation, { data, loading, error }] = useCreateCheckoutSessionMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateCheckoutSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreateCheckoutSessionMutation, CreateCheckoutSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCheckoutSessionMutation, CreateCheckoutSessionMutationVariables>(CreateCheckoutSessionDocument, options);
      }
export type CreateCheckoutSessionMutationHookResult = ReturnType<typeof useCreateCheckoutSessionMutation>;
export type CreateCheckoutSessionMutationResult = Apollo.MutationResult<CreateCheckoutSessionMutation>;
export type CreateCheckoutSessionMutationOptions = Apollo.BaseMutationOptions<CreateCheckoutSessionMutation, CreateCheckoutSessionMutationVariables>;
export const CreateRequestDocument = gql`
    mutation CreateRequest($input: CreateRequestInput!) {
  createRequest(input: $input) {
    ...Request
  }
}
    ${RequestFragmentDoc}`;
export type CreateRequestMutationFn = Apollo.MutationFunction<CreateRequestMutation, CreateRequestMutationVariables>;

/**
 * __useCreateRequestMutation__
 *
 * To run a mutation, you first call `useCreateRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRequestMutation, { data, loading, error }] = useCreateRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRequestMutation(baseOptions?: Apollo.MutationHookOptions<CreateRequestMutation, CreateRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRequestMutation, CreateRequestMutationVariables>(CreateRequestDocument, options);
      }
export type CreateRequestMutationHookResult = ReturnType<typeof useCreateRequestMutation>;
export type CreateRequestMutationResult = Apollo.MutationResult<CreateRequestMutation>;
export type CreateRequestMutationOptions = Apollo.BaseMutationOptions<CreateRequestMutation, CreateRequestMutationVariables>;
export const ClaimRequestDocument = gql`
    mutation ClaimRequest($requestId: ID!) {
  claimRequest(requestId: $requestId)
}
    `;
export type ClaimRequestMutationFn = Apollo.MutationFunction<ClaimRequestMutation, ClaimRequestMutationVariables>;

/**
 * __useClaimRequestMutation__
 *
 * To run a mutation, you first call `useClaimRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimRequestMutation, { data, loading, error }] = useClaimRequestMutation({
 *   variables: {
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useClaimRequestMutation(baseOptions?: Apollo.MutationHookOptions<ClaimRequestMutation, ClaimRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClaimRequestMutation, ClaimRequestMutationVariables>(ClaimRequestDocument, options);
      }
export type ClaimRequestMutationHookResult = ReturnType<typeof useClaimRequestMutation>;
export type ClaimRequestMutationResult = Apollo.MutationResult<ClaimRequestMutation>;
export type ClaimRequestMutationOptions = Apollo.BaseMutationOptions<ClaimRequestMutation, ClaimRequestMutationVariables>;
export const DropRequestDocument = gql`
    mutation DropRequest($requestId: ID!) {
  dropRequest(requestId: $requestId)
}
    `;
export type DropRequestMutationFn = Apollo.MutationFunction<DropRequestMutation, DropRequestMutationVariables>;

/**
 * __useDropRequestMutation__
 *
 * To run a mutation, you first call `useDropRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDropRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dropRequestMutation, { data, loading, error }] = useDropRequestMutation({
 *   variables: {
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useDropRequestMutation(baseOptions?: Apollo.MutationHookOptions<DropRequestMutation, DropRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DropRequestMutation, DropRequestMutationVariables>(DropRequestDocument, options);
      }
export type DropRequestMutationHookResult = ReturnType<typeof useDropRequestMutation>;
export type DropRequestMutationResult = Apollo.MutationResult<DropRequestMutation>;
export type DropRequestMutationOptions = Apollo.BaseMutationOptions<DropRequestMutation, DropRequestMutationVariables>;
export const CompleteRequestDocument = gql`
    mutation CompleteRequest($requestId: ID!, $imageKeys: [String!]!) {
  completeRequest(requestId: $requestId, imageKeys: $imageKeys)
}
    `;
export type CompleteRequestMutationFn = Apollo.MutationFunction<CompleteRequestMutation, CompleteRequestMutationVariables>;

/**
 * __useCompleteRequestMutation__
 *
 * To run a mutation, you first call `useCompleteRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeRequestMutation, { data, loading, error }] = useCompleteRequestMutation({
 *   variables: {
 *      requestId: // value for 'requestId'
 *      imageKeys: // value for 'imageKeys'
 *   },
 * });
 */
export function useCompleteRequestMutation(baseOptions?: Apollo.MutationHookOptions<CompleteRequestMutation, CompleteRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteRequestMutation, CompleteRequestMutationVariables>(CompleteRequestDocument, options);
      }
export type CompleteRequestMutationHookResult = ReturnType<typeof useCompleteRequestMutation>;
export type CompleteRequestMutationResult = Apollo.MutationResult<CompleteRequestMutation>;
export type CompleteRequestMutationOptions = Apollo.BaseMutationOptions<CompleteRequestMutation, CompleteRequestMutationVariables>;
export const DeleteRequestDocument = gql`
    mutation DeleteRequest($requestId: ID!) {
  deleteRequest(requestId: $requestId)
}
    `;
export type DeleteRequestMutationFn = Apollo.MutationFunction<DeleteRequestMutation, DeleteRequestMutationVariables>;

/**
 * __useDeleteRequestMutation__
 *
 * To run a mutation, you first call `useDeleteRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRequestMutation, { data, loading, error }] = useDeleteRequestMutation({
 *   variables: {
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useDeleteRequestMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRequestMutation, DeleteRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRequestMutation, DeleteRequestMutationVariables>(DeleteRequestDocument, options);
      }
export type DeleteRequestMutationHookResult = ReturnType<typeof useDeleteRequestMutation>;
export type DeleteRequestMutationResult = Apollo.MutationResult<DeleteRequestMutation>;
export type DeleteRequestMutationOptions = Apollo.BaseMutationOptions<DeleteRequestMutation, DeleteRequestMutationVariables>;
export const ResumeReviewDocument = gql`
    mutation ResumeReview($input: ResumeReviewInput!) {
  resumeReview(input: $input)
}
    `;
export type ResumeReviewMutationFn = Apollo.MutationFunction<ResumeReviewMutation, ResumeReviewMutationVariables>;

/**
 * __useResumeReviewMutation__
 *
 * To run a mutation, you first call `useResumeReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResumeReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resumeReviewMutation, { data, loading, error }] = useResumeReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResumeReviewMutation(baseOptions?: Apollo.MutationHookOptions<ResumeReviewMutation, ResumeReviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResumeReviewMutation, ResumeReviewMutationVariables>(ResumeReviewDocument, options);
      }
export type ResumeReviewMutationHookResult = ReturnType<typeof useResumeReviewMutation>;
export type ResumeReviewMutationResult = Apollo.MutationResult<ResumeReviewMutation>;
export type ResumeReviewMutationOptions = Apollo.BaseMutationOptions<ResumeReviewMutation, ResumeReviewMutationVariables>;
export const MeDocument = gql`
    query Me {
  me {
    ...User
  }
}
    ${UserFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export function useMeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeSuspenseQueryHookResult = ReturnType<typeof useMeSuspenseQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input)
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;

type Properties<T> = Required<{
  [K in keyof T]: z.ZodType<T[K], any, T[K]>;
}>;

type definedNonNullAny = {};

export const isDefinedNonNullAny = (v: any): v is definedNonNullAny => v !== undefined && v !== null;

export const definedNonNullAnySchema = z.any().refine((v) => isDefinedNonNullAny(v));

export const ErrorTypeSchema = z.nativeEnum(ErrorType);

export const MentorStatusSchema = z.nativeEnum(MentorStatus);

export const PaymentStatusSchema = z.nativeEnum(PaymentStatus);

export const PayoutStatusSchema = z.nativeEnum(PayoutStatus);

export const RequestStatusSchema = z.nativeEnum(RequestStatus);

export const RequestTypeSchema = z.nativeEnum(RequestType);

export function CreateRequestInputSchema(): z.ZodObject<Properties<CreateRequestInput>> {
  return z.object({
    companyId: z.string(),
    jobTitle: z.string(),
    jobUrl: z.string(),
    resumeKey: z.string(),
    type: RequestTypeSchema
  })
}

export function MentorOnboardInputSchema(): z.ZodObject<Properties<MentorOnboardInput>> {
  return z.object({
    companyId: z.string(),
    email: z.string()
  })
}

export function MentorVerificationInputSchema(): z.ZodObject<Properties<MentorVerificationInput>> {
  return z.object({
    token: z.string()
  })
}

export function ResumeReviewInputSchema(): z.ZodObject<Properties<ResumeReviewInput>> {
  return z.object({
    email: z.string(),
    name: z.string(),
    resumeKey: z.string()
  })
}

export function UpdateUserInputSchema(): z.ZodObject<Properties<UpdateUserInput>> {
  return z.object({
    firstName: z.string().nullish(),
    lastName: z.string().nullish()
  })
}
